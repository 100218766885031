<template>
  <div>
    <portal to="page-top-title">Senders</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'senders-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/senders" :url-query="{ with: ['category:id,name'] }" data-prop="senders" loader>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Category</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="sender in senders" :key="`senders-tr-${sender.id}`" :value="sender">
          <td>{{ sender.id }}</td>
          <td>{{ sender.name }}</td>
          <td>
            <router-link :to="{ name: 'senders-categories-single', params: { id: sender.category.id } }">{{ sender.category.name }}</router-link>
          </td>
          <td class="text-center">
            <badge-active :value="sender.active"/>
          </td>
          <td class="text-right">{{ sender.created_at }}</td>
          <td class="text-right">{{ sender.updated_at }}</td>
          <td class="text-right">
            <template v-if="sender.active">
              <btn icon="fa-ban" color="danger" @click.stop.native="confirmAction(sender.id, 'deactivate')">Deactivate</btn>
            </template>
            <template v-else>
              <btn icon="fa-check" color="success" @click.stop.native="confirmAction(sender.id, 'activate')">Activate</btn>
            </template>
            <btn :route="{ name: 'senders-edit', params: { id: sender.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'senders-single', params: { id: sender.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Btn from '@/views/components/simple/Btn'
import Action from '@/modules/utils/Action'

export default {
  name: 'SendersList',
  metaInfo: {
    title: 'Senders'
  },
  components: {
    BoxTable,
    BadgeActive,
    Btn
  },
  data () {
    return {
      senders: []
    }
  },
  methods: {
    confirmAction (id, action) {
      Action.confirm({
        id: id,
        action: action,
        name: 'Sender',
        route: '/senders/:id/action',
        collection: this.senders
      })
    }
  }
}
</script>
