const collectionOneItem = function (items, newItem) {
  items.forEach(function (currentItem) {
    currentItem.id === newItem.id && Object.assign(currentItem, newItem)
  })
}

const object = function (oldItem, newItem) {
  Object.assign(oldItem, newItem)
}

const Transform = {
  collectionOneItem,
  object
}

export default Transform
