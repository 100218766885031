import axios from 'axios'
import Alert from '@/modules/utils/Alert'
import Transform from '@/modules/utils/Transform'

const options = {
  id: 0,
  action: '',
  route: '',
  name: 'Entity',
  entity: null,
  collection: null
}

const confirm = function (externalOptions) {
  Object.assign(options, externalOptions)

  Alert
    .confirmAction({ title: `${options.name} #${options.id}` }, options.action)
    .then((res) => { res.isConfirmed && make(options) })
}

const make = function (options) {
  options.route = options.route.replace(':id', options.id)

  axios
    .put(options.route, { action: options.action })
    .then((res) => {
      const { data: { data } } = res

      options.entity
        ? Transform.object(options.entity, data)
        : Transform.collectionOneItem(options.collection, data)

      Alert.toast({ title: `${options.name} #${data.id} updated!` })
    }).catch(() => {
      Alert.toast({ title: 'Update error!', icon: 'error' })
    })
}

const Action = {
  confirm
}

export default Action
